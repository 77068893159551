import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseRoutes, EmptyContainerComponent } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'causalecontabanalitica',
        () => import('./causale-contab-analitica/causale-contab-analitica.module').then(m => m.CausaleContabAnaliticaModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'causalecontabile',
        () => import('./causale-contabile/causale-contabile.module').then(m => m.CausaleContabileModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'causalemagazzino',
        () => import('./causale-magazzino/causale-magazzino.module').then(m => m.CausaleMagazzinoModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'centrodicosto',
        () => import('./centro-di-costo/centro-di-costo.module').then(m => m.CentroDiCostoModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'contropartita',
        () => import('./contro-partita/contro-partita.module').then(m => m.ControPartitaModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'divisioneaziendale',
        () => import('./divisione-aziendale/divisione-aziendale.module').then(m => m.DivisioneAziendaleModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'listino',
        () => import('./listino/listino.module').then(m => m.ListinoModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'magazzino',
        () => import('./magazzino/magazzino.module').then(m => m.MagazzinoModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tipobollafattura',
        () => import('./tipo-bolla-fattura/tipo-bolla-fattura.module').then(m => m.TipoBollaFatturaModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'reporttestexternal',
        () => import('./report-test-external/report-test-external.module').then(m => m.ReportTestExternalModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'reporttest',
        () => import('./report-test/report-test.module').then(m => m.ReportTestModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'reportlongopmodel',
        () => import('./report-long-op/report-long-op.module').then(m => m.ReportLongOpModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),

  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      {
        path: 'manage/pricing',
        component: EmptyContainerComponent,
        loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule)
      }
    ],
    () => import('@nts/std').then(mod => mod.EmptyContainerComponent)
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
